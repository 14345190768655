import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { WIX_COMMENTS_WIDGET_APP_DEF_ID } from '~/constants/app-def-ids';
import { CategoryService } from '~/services/category-service';
import { PublicDataStore } from '~/services/public-data-service/public-data-service';

export const setCategoryId = async ({
  categoryService,
  publicDataStore,
  flowAPI,
}: {
  categoryService: CategoryService;
  publicDataStore: PublicDataStore;
  flowAPI: EditorScriptFlowAPI;
}) => {
  let categoryId;
  try {
    categoryId = await categoryService.createCategory({
      appId: WIX_COMMENTS_WIDGET_APP_DEF_ID,
    });
  } catch (e) {
    flowAPI.errorMonitor.captureException(
      e instanceof Error ? e : new Error('failed to create a new category during installation'),
      { tags: { context: 'createCategory' } },
    );
  }

  if (categoryId) {
    await publicDataStore?.setCategoryId({
      categoryId,
    });
  }

  await publicDataStore?.setIsResourceCoupledToUrl();
};
