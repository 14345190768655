import {
  ReactionTypeType as CommentReactions,
  RepliesReactionTypeType as ReplyReactions,
  Type as MarkedLabelType,
  IconTypeType as ReactionIcon,
  QueryCategoryRequest,
  Category as ApiCategory,
} from '@wix/ambassador-comments-v1-category/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  createCategory as apiCreateCategory,
  queryCategory as apiQueryCategory,
  getCategory as apiGetCategory,
  updateCategory as apiUpdateCategory,
} from '@wix/ambassador-comments-v1-category/http';
import { assertFields } from '../../ts-utils';
import { fromServerCategory } from './category-service.mappers';
import { Category } from './category-service.types';

export const createCategoryService = ({ httpClient }: { httpClient: IHttpClient }) => {
  const service = {
    createCategory: createCategory(httpClient),
    queryCategories: queryCategories(httpClient),
    updateCategory: updateCategory(httpClient),
    getCategory: getCategory(httpClient),
  };

  return service;
};

const createCategory =
  (httpClient: IHttpClient) =>
  async ({ appId }: { appId: string }) => {
    const defaults: Partial<ApiCategory> = {
      name: 'Comments',
      guestCommenting: true,
      guestReactions: true,
      reactionType: CommentReactions.REACTIONS,
      repliesReactionType: ReplyReactions.REACTIONS,
      mainReaction: { iconType: ReactionIcon.THUMBS_UP },
      ratingsSettings: { ratingsEnabled: false, ratingRequired: false },
      markedCommentLabelType: MarkedLabelType.FEATURED,
    };
    const { data } = await httpClient.request(
      apiCreateCategory({ category: { ...defaults, appId } }),
    );
    return data.category?.id ?? undefined;
  };

const queryCategories = (httpClient: IHttpClient) =>
  async function (request: QueryCategoryRequest = { query: {} }) {
    const { data } = await httpClient.request(apiQueryCategory(request));
    assertFields(data, ['categories'], 'apiQueryCategory reponse');

    return data.categories.map(fromServerCategory);
  };

const getCategory = (httpClient: IHttpClient) => async (categoryId: string) => {
  const { data } = await httpClient.request(apiGetCategory({ categoryId }));
  assertFields(data, ['category'], 'apiGetCategory reponse');

  return fromServerCategory(data.category);
};

const updateCategory = (httpClient: IHttpClient) =>
  async function ({
    currentCategory,
    update,
  }: {
    currentCategory: Category;
    update: Omit<ApiCategory, 'revision' | 'id'>;
  }) {
    //  This API has some quirks that you should be aware of.
    // 1. It requires a 'mask' to be sent with the request, indicating which fields are being updated.
    // any fields not in the mask will be ignored, even if they're present in the request body.
    // 2. Certain fields ('id', 'revision', 'name', 'guestCommenting', 'guestReactions') are always required,
    // even if their values are not being updated.
    const { id, revision, name, guestCommenting, guestReactions } = currentCategory;

    const { data } = await httpClient.request(
      apiUpdateCategory({
        category: {
          ...{ id, revision, name, guestCommenting, guestReactions },
          ...update,
        },
        mask: Object.keys(update),
      }),
    );
    assertFields(data, ['category'], 'apiUpdateCategory reponse');

    return fromServerCategory(data.category);
  };
