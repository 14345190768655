import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { createPublicDataStore } from '~/services/public-data-service/public-data-service';
import { createCategoryService } from '../services/category-service/category-service';
import { getIsBlogComments } from '../components/CommentsWidget/controller/get-is-blog-comments';
import { setResponsiveness } from './set-responsiveness';
import { setCategoryId } from './set-category-id';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  if (getIsBlogComments(appDefinitionId)) {
    return;
  }
  const categoryService = createCategoryService({ httpClient: flowAPI.httpClient });

  editorSDK.addEventListener('widgetAdded', async (event) => {
    const publicDataStore = await createPublicDataStore({
      compId: event.detail.componentRef.id,
      editorSDK,
    });
    await setCategoryId({ publicDataStore, categoryService, flowAPI });

    await setResponsiveness({ compRef: event.detail.componentRef, editorSDK });
  });
};
